function createGuestBook() {
    var grid = jQuery('.masonry-guestbook');
        grid.masonry({
        itemSelector:'.masonry-item-guestbook',
        gutter:10,
        columnWidth: 10
    });
    jQuery('.btn-add-new-comments').on('click',function(){
        jQuery('.add-comments-container').removeClass('hidden');
    });
}
