function singlePageLightGAllery($) {
    var galleryItems = jQuery('[data-light-gallery-image]');
    galleryItems.on('click',function(){
        var selectedIndex = jQuery(this).data('light-gallery-image');
        jQuery(this).lightGallery({
            dynamic: true,
            dynamicEl: lightGalleryDynamicImages, // array is created in the php file content-single.php
            index: selectedIndex,
            thumbnail:true,
            download:false
        });
    });

    jQuery('#video-gallery').lightGallery({
        videojs:true
    });

    $('[data-show-video]').on('click', function(e){
        e.preventDefault();
        $('#video-gallery li').eq(0).trigger('click');
    });
}
